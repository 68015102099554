import React from 'react'

import { graphql } from 'gatsby'
import PortableText from '@sanity/block-content-to-react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import Collapsible from 'react-collapsible'

import { IoIosArrowDown } from "react-icons/io";

import styles from './generalPage.module.css'
import serializers from '../lib/serializers'
import ImageViewer from 'react-simple-image-viewer'
import LinkLists from '../lib/linkLists'
import SEO from '../components/seo'


export const query = graphql`
    query GeneralQuery($id: String!) {
        general: sanityContentPage(id: {eq: $id}) {
            id
            title
            subTitle
            directory
            _rawContentSections(resolveReferences: {maxDepth: 20})
            _rawAdditionalContent(resolveReferences: {maxDepth: 20})
        }
    }
`

class GeneralPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            subSideContent: [],
            activeAccordion: 0,
            currentImage: [],
            isViewerOpen: false,
        }
    }

    openImageViewer = (url) => {
        this.setState({
            currentImage: [url],
            isViewerOpen: true
        })
    };
     
    closeImageViewer = () => {
        this.setState({
            currentImage: [],
            isViewerOpen: false
        })
    };

    openAccordion = (idx) => {
        const subSideContent = [];

        if (content.sideContent) {
            if (content.sideContent.linkLists) {
                const linkLists = <LinkLists key={content._key} content={content.sideContent.linkLists} styles={styles} />
                subSideContent.push(linkLists);
            }
            if (content.sideContent.sideImage) {
                subSideContent.push(this.getSideImage(content.sideContent.sideImage));
            }
            if (content.sideContent.quote) {
                subSideContent.push(this.getQuote(content.sideContent.quote));
            }
        }

        this.setState({
            activeAccordion: idx,
            subSideContent: subSideContent,
        })
    }

    closeAccordion = (idx) => {
        if (this.state.activeAccordion === idx) {
            this.setState({
                activeAccordion: 0,
                subSideContent: []
            })
        }
    }

    getQuote = (quote) => {
        if (quote.mainImage.asset) {
            return (
                <div className={styles.quoteWrapper}>
                    <img src={quote.mainImage.asset.url}></img>
                    <div>
                        <p>{quote.quote}</p>
                        <p className={styles.author}>- {quote.authors[0].author.name}</p>
                    </div>
                </div>
            )
        }
        
        return null;
    }

    getImage = (sideImage) => {
        const fullImage = sideImage.FullImage && sideImage.FullImage.asset ? sideImage.FullImage.asset : null;
        const smallImage = sideImage.smallImage && sideImage.smallImage.asset ? sideImage.smallImage.asset : null;
        const image = smallImage !== null ? smallImage : fullImage 

        if (image !== null) {
            return (
                <div key={image.assetId} className={styles.sideImageContainer}>
                    <span>{sideImage.description}</span>
                    <img src={image.url} className={styles.sideImage} onClick={ () => this.openImageViewer(fullImage ? fullImage.url : smallImage.url) } />
                </div>
            )
        }
    }

    render() {
        const data = this.props.data;

        if (!data.general) {
            throw new Error(
                'Missing "Page". Open the Studio and add some content'
            )
        }

        if (this.state.isViewerOpen) {
            return (
                <ImageViewer
                    src={ this.state.currentImage }
                    onClose={ this.closeImageViewer }
                /> 
            )
        }
        
        const generalData = data.general;  
        if (generalData._rawContentSections.length === 0) {
            return (
                <Layout>
                    <div className={styles.root}>
                        <h1>No data yet</h1>
                    </div>
                </Layout>
            )
        }

        let sideComponent = null;
        if (generalData._rawAdditionalContent) {
            sideComponent = <LinkLists content={generalData._rawAdditionalContent} styles={styles} />;
        }

        const contentComponents = generalData._rawContentSections.map((content, idx) => {   
            let linkListsComponent = '';
            let quoteComponent = '';
            let imageComponent = '';

            const sideContent = content.sideContent || null;

            if(sideContent !== null) {
                if(sideContent.linkLists) {
                    linkListsComponent = <LinkLists content={sideContent.linkLists} styles={styles} />;
                }
                if(sideContent.quote) {
                    quoteComponent = this.getQuote(sideContent.quote);
                }
                if(sideContent.sideImage) {
                    imageComponent = this.getImage(sideContent.sideImage);
                }
            }

            if (content.showAsAccordion !== true) {
                if(!content.paragraph) {
                    return (
                        <div key={content._key} className={styles.contentDiv}>
                            <h2 className="large">{content.contentSectionTitle}</h2>
                            <h2>{content.contentSectionSubTitle}</h2>
                            {imageComponent}
                            {quoteComponent}
                            {linkListsComponent}
                        </div>
                    )
                }

                return (
                    <div key={content._key} className={styles.contentDiv}>
                        <h2 className="large">{content.contentSectionTitle}</h2>
                        <h2>{content.contentSectionSubTitle}</h2>
                        <div className={styles.contentSection}>
                            {imageComponent}
                            <PortableText
                                blocks={content.paragraph}
                                serializers={serializers}
                                className={styles.portableText}
                            />
                            {quoteComponent}
                            {linkListsComponent}
                        </div>
                    </div>
                )
            } else {
                const triggerTitle =
                    <div className={styles.triggerTitle}>
                        {content.contentSectionTitle}
                        <span><IoIosArrowDown /></span>
                    </div>

                return (
                    <Collapsible trigger={triggerTitle} key={content._key}
                        className={styles.sectionBox}
                        triggerClassName={styles.sectionTrigger}
                        triggerOpenedClassName={styles.sectionTriggerOpened}
                        openedClassName={styles.sectionOpened}
                        transitionTime={100}
                        transitionCloseTime={100}
                        onOpening={() => this.openAccordion(idx)}
                        onClosing={() => this.closeAccordion(idx)}
                        open={this.state.activeAccordion === idx ? true : false}
                        lazyRender={true}
                    >
                        <div className={styles.sectionContent} style={{display: this.state.activeAccordion === idx ? 'block' : 'none'}}>
                            <h2>{content.contentSectionSubTitle}</h2>
                            {imageComponent}
                            <PortableText 
                                blocks={content.paragraph}
                                serializers={serializers}
                                className={styles.portableText}
                            />
                            {quoteComponent}
                            {linkListsComponent}
                        </div>
                    </Collapsible>
                )
            }            
        })

        const path = generalData.directory + '/' + generalData.title.replace(/\s+/g, '-').toLowerCase();

        return (
            <Layout>
                <SEO
                    title={generalData.title}
                    description={generalData.subTitle}
                />
                <div className={styles.root}>
                    <p className={styles.upperLeftLink}><Link to={`/`} style={{ textDecoration: 'none' }}>Home</Link> {path}</p>
                    <h1>{generalData.title}</h1>
                    <h2>{generalData.subTitle}</h2>
                    <div className={styles.containers}>
                        {contentComponents}
                        {sideComponent}
                    </div>
                    
                </div>
            </Layout>
        )
    }
}

export default GeneralPage;